<div class="form-contato">
    <div class="titulo-form-contato">
        Contato
    </div>
    <div class="mensagem-form-contato">
        Os campos marcados com (*) são obrigatórios.
    </div>
    <form [formGroup]="formContato" novalidate (submit)="enviar()">
        <div class="linha-form">
            <div>
                <mat-form-field>
                    <mat-label>Seu Nome *</mat-label>
                    <input matInput formControlName="nome" type="text" autocomplete="off" />
                </mat-form-field>
            </div>
        </div>
        <div class="linha-form">
            <div>
                <mat-form-field>
                    <mat-label>Seu Sobrenome</mat-label>
                    <input matInput formControlName="sobrenome" type="text" autocomplete="off" />
                </mat-form-field>
            </div>
        </div>
        <div class="linha-form">
            <div>
                <mat-form-field>
                    <mat-label>Empresa</mat-label>
                    <input matInput formControlName="empresa" type="text" autocomplete="off" placeholder="Empresa" />
                </mat-form-field>
            </div>
        </div>
        <div class="linha-form flex">
            <div>
                <mat-form-field>
                    <mat-label>DDD + Telefone *</mat-label>
                    <input matInput formControlName="telefone" type="text" autocomplete="off" dta-mascara="fone" />
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <mat-label>Seu E-Mail *</mat-label>
                    <input matInput formControlName="email" type="email" autocomplete="off" />
                </mat-form-field>
            </div>
        </div>
        <div class="linha-form">
            <div>
                <mat-form-field>
                    <mat-label>Mensagem *</mat-label>
                    <textarea matInput formControlName="mensagem" autocomplete="off"></textarea>
                </mat-form-field>
            </div>
        </div>
        <div class="acoes-contato">
            <button class="botao-form" [disabled]="formContato.invalid" type="submit">Enviar</button>
        </div>
    </form>
</div>