<div class="outras-informacoes">
    <div class="titulo-edicao">
        Textos Informativos
    </div>
    <form [formGroup]="formTextos" novalidate (submit)="atualizar()">
        <fieldset>
            <legend>Seção 1</legend>
            <div class="linha-form-texto">
                <input type="text" formControlName="secao1Titulo" placeholder="Titulo da Seção 1" />
            </div>
            <div class="linha-form-texto">
                <textarea type="text" formControlName="secao1Texto" placeholder="Texto da Seção 1"></textarea>
            </div>
        </fieldset>
        <fieldset>
            <legend>Seção 2</legend>
            <div class="linha-form-texto">
                <input type="text" formControlName="secao2Titulo" placeholder="Titulo da Seção 2" />
            </div>
            <div class="linha-form-texto">
                <textarea type="text" formControlName="secao2Texto" placeholder="Texto da Seção 2"></textarea>
            </div>
        </fieldset>
        <fieldset>
            <legend>Seção 3</legend>
            <div class="linha-form-texto">
                <input type="text" formControlName="secao3Titulo" placeholder="Titulo da Seção 3" />
            </div>
            <div class="linha-form-texto">
                <textarea type="text" formControlName="secao3Texto" placeholder="Texto da Seção 3"></textarea>
            </div>
        </fieldset>

        <fieldset     class="acoes-form-texto">
            <div>
                <button type="submit" class="botao ok">
                    <svg-icon src="/assets/images/svg/icon_check.svg"></svg-icon>
                </button>
            </div>
        </fieldset>
    </form>
</div>