import { Injectable } from "@angular/core";
import { CanActivate, CanLoad, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { UsuarioService } from './services/usuario.service';

@Injectable()
export class AcessosGuard implements CanLoad, CanActivate {
    constructor(private usuarioService: UsuarioService, private router: Router) { }
    canActivate(): Observable<boolean> {
        return this.verificarPermissao();
    }

    canLoad(): Observable<boolean> {
        return this.verificarPermissao();
    }

    verificarPermissao(): Observable<boolean> {
        let logado = this.usuarioService.usuarioAutenticado();
        if (!logado) {
            this.router.navigateByUrl("login");
            return of(false);
        }

        return of(true);
    }
}