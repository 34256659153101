import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContatoService } from '../../../services/contato.service';
import { MensagemService } from '../../../services/mensagem.service';

@Component({
    selector: "app-form-contato",
    templateUrl: "./form-contato.component.html",
    styleUrls: ["./form-contato.component.css"]
})
export class FormContatoComponent implements OnInit {
    constructor(private formBuilder: FormBuilder, private contatoService: ContatoService, private mensagemService: MensagemService) { }

    formContato: FormGroup;

    ngOnInit() {
        this.formContato = this.formBuilder.group({
            nome: this.formBuilder.control(null, [Validators.required]),
            sobrenome: this.formBuilder.control(null),
            empresa: this.formBuilder.control(null),
            telefone: this.formBuilder.control(null, [Validators.required]),
            email: this.formBuilder.control(null, [Validators.required]),
            mensagem: this.formBuilder.control(null, [Validators.required])
        });
    }

    enviar() {
        this.contatoService.enviar(this.formContato.value).subscribe(() => this.sucesso(), () => this.erro());
    }

    private sucesso() {
        this.mensagemService.exibirSucesso("Contato", "E-Mail enviado com sucesso!");
    }
    private erro() {
        this.mensagemService.exibirErro("Contato", "Ocorreu um erro ao enviar o e-mail");
    }
}