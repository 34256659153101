import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { RentabilidadeMensal } from '../models/rentabilidade-mensal.model';

@Injectable()
export class RentabilidadeMensalService {
    constructor(private http: HttpClient) { }

    inserir(parametro: RentabilidadeMensal): Observable<any> {
        return this.http.post<any>("api/rentabilidade/mensal/inserir", parametro);
    }

    atualizar(parametro: RentabilidadeMensal): Observable<any> {
        return this.http.post<any>("api/rentabilidade/mensal/atualizar", parametro);
    }

    remover(id: number): Observable<any> {
        return this.http.post<any>("api/rentabilidade/mensal/remover", { id: id });
    }

    listar(): Observable<RentabilidadeMensal[]> {
        return this.http.get<RentabilidadeMensal[]>("api/rentabilidade/mensal");
    }
}