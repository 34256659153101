<div class="aviso-sistema"
     [class.erro]="tipo==='erro'"
     [class.alerta]="tipo==='alerta'"
     [class.sucesso]="tipo==='sucesso'"
     [class.info]="tipo==='info'"
     [class.visivel]="visivel">
    <div class="conteudo-aviso-sistema">
        <svg-icon *ngIf="tipo" [src]="obterIcone()"></svg-icon>
        <div class="texto-aviso leve" [innerHtml]="mensagem"></div>
    </div>
    <div class="barra-tempo" [style.width.%]="larguraBarra"></div>
</div>