<div id="home">
    <div class="home">
        <div class="texto-home">
            <div class="texto-topo">
                A <span>Simétrica</span> é uma <span>gestora</span><br />
                de <span>recursos</span> independente.
            </div>
            <div class="texto-baixo">
                <div>
                    Com foco em <span>renda variável</span><br />
                    buscamos resultados consistentes no <span>longo prazo</span>.<br /><br />
                    Nossa equipe conta com <span>longa experiência</span><br />
                    no <span>mercado de ações</span>.
                </div>
            </div>
        </div>
        <div class="grafico-home">
            <img src="./assets/images/svg/grafico.svg" alt="grafico" />
        </div>
        <!-- <div class="invista-aqui-home">
            <app-invista-aqui></app-invista-aqui>
        </div> -->
    </div>
    <div class="rentabilidade">
        <div class="titulo-rentabilidade">Rentabilidade Acumulada</div>
        <app-rentabilidade-acumulada></app-rentabilidade-acumulada>
        <div class="titulo-rentabilidade separador">Rentabilidade Mês a Mês</div>
        <app-rentabilidade-mensal></app-rentabilidade-mensal>
    </div>
    <div class="rentabilidade-mobile">
        <div class="item-rentabilidade-mobile">
            <div class="icone-rentabilidade-mobile">
                <img src="./assets/images/png/icon_rotate.png" alt="rotate" />
            </div>
            <div class="texto-retabilidade-mobile">
                <div class="titulo-rentabilidade-mobile">Tabela <span class="bold">Rentabilidade Acumulada</span></div>
                <div class="descricao-mobile">Rotacione seu aparelho no sentido horizontal para visualização da tabela</div>
            </div>
        </div>
        <div class="item-rentabilidade-mobile">
            <div class="icone-rentabilidade-mobile">
                <img src="./assets/images/png/icon_rotate.png" alt="rotate" />
            </div>
            <div class="texto-retabilidade-mobile">
                <div class="titulo-rentabilidade-mobile">Tabela <span class="bold">Mês a Mês</span></div>
                <div class="descricao-mobile">Rotacione seu aparelho no sentido horizontal para visualização da tabela</div>
            </div>
        </div>
    </div>
    <div class="base-info-home">
        <app-info-home></app-info-home>
    </div>
</div>