import { Injectable } from "@angular/core";
import { CookieService } from 'ngx-cookie-service';
import { TokenInfo } from '../models/usuario.model';

@Injectable()
export class AppCookieService {
    private readonly NomeCookieAutenticacao: string = "SimetricaAuthCookie"

    constructor(private cookies: CookieService) { }

    gravarCookieToken(token: TokenInfo) {
        let strToken = JSON.stringify(token);
        let dataValidade: Date = new Date(token.dataValidade);
        this.cookies.set(this.NomeCookieAutenticacao, strToken, dataValidade);
    }

    obterCookieToken(): TokenInfo {
        let strToken = this.cookies.get(this.NomeCookieAutenticacao);
        if (!strToken) {
            return undefined;
        }
        let tokenInfo: TokenInfo = JSON.parse(strToken);
        return tokenInfo;
    }

    removerCookieToken() {
        this.cookies.delete(this.NomeCookieAutenticacao);
    }

    removerCookie(chave: string) {
        this.cookies.delete(chave);
    }
}