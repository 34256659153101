import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndexComponent } from './site/index/index.component';
import { AdminComponent } from './site/admin/admin.component';
import { AcessosGuard } from './acessos.guard';
import { LoginComponent } from './site/admin/login/login.component';

const routes: Routes = [
    { path: "", component: IndexComponent },
    { path: "admin", component: AdminComponent, canActivate: [AcessosGuard], canLoad: [AcessosGuard] },
    { path: "login", component: LoginComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: "enabled",
        anchorScrolling: "enabled",
        useHash: true
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
