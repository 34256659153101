import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { Contato } from '../models/contato.model';

@Injectable()
export class ContatoService {
    constructor(private http: HttpClient) { }

    enviar(contato: Contato): Observable<any> {
        return this.http.post<any>("/api/contato/enviar", contato);
    }

    listar(): Observable<Contato[]> {
        return this.http.get<Contato[]>("/api/contato");
    }

    remover(id: number): Observable<any> {
        return this.http.post<any>("/api/contato/remover", { id: id });
    }
}