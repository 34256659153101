import { Component, OnInit } from "@angular/core";
import { ConteudoService } from '../../services/conteudo.service';

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.css"]
})
export class HomeComponent implements OnInit {
    constructor(private conteudoService: ConteudoService) { }
    dataInicio: string;

    ngOnInit() {
        this.conteudoService.obter().subscribe(conteudo => this.dataInicio = conteudo ? conteudo.dataInicio : null);
    }
}