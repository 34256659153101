import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UsuarioService } from '../../../services/usuario.service';

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
    constructor(private formBuilder: FormBuilder, private usuarioService: UsuarioService, private router: Router) { }

    acessoNegado: boolean = false;
    formLogin: FormGroup;

    ngOnInit() {
        this.formLogin = this.formBuilder.group({
            login: this.formBuilder.control(null, [Validators.required]),
            senha: this.formBuilder.control(null, [Validators.required])
        });
    }

    autenticar() {
        this.usuarioService.autenticar(this.formLogin.value).subscribe(() => this.limparERedirecionar(), () => {
            this.acessoNegado = true;
            this.limparForm();
        });
    }

    private limparForm() {
        this.formLogin.setValue({
            login: null,
            senha: null
        });

        this.formLogin.reset();
        this.formLogin.markAsUntouched();
        this.formLogin.markAsPristine();
    }

    private limparERedirecionar() {
        this.limparForm();

        if (this.usuarioService.usuarioAutenticado()) {
            this.acessoNegado = false;
            this.router.navigateByUrl("admin");
        }
    }
}