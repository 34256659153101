import { Component, ContentChildren, Input, QueryList } from "@angular/core";
import { GridColumnComponent } from './grid-column.component';

@Component({
    selector: "app-grid",
    templateUrl: "./grid.component.html"
})
export class GridComponent {
    @ContentChildren(GridColumnComponent) colunas: QueryList<GridColumnComponent>;
    @Input() dados: any[];
}
