<div class="rodape">
    <div class="icones-rodape">
        <div class="cvm">
            <a href="http://www.cvm.gov.br">
                <img src="/assets/images/png/logo_cvm.png" alt="cvm" />
            </a>
        </div>
        <div class="anbima">
            <a href="http://www.anbima.com.br">
                <img src="/assets/images/png/selo-adesao-provisoria-anbima.png" alt="anbima" />
            </a>
        </div>
    </div>
    <div class="texto-rodape">
        <div>
            As informações contidas neste site são meramente informativas e não constituem qualquer tipo de aconselhamento de investimentos, não devendo ser utilizadas com este propósito.
        </div>
        <div>
            A RENTABILIDADE DIVULGADA NÃO É LÍQUIDA DE IMPOSTOS.
        </div>
        <div>
            A RENTABILIDADE OBTIDA NO PASSADO NÃO REPRESENTA GARANTIA DE RENTABILIDADE FUTURA. FUNDOS E CARTEIRAS DE INVESTIMENTO NÃO CONTAM COM GARANTIA DO ADMINISTRADOR DO FUNDO,
            DO GESTOR DA CARTEIRA, DE QUALQUER MECANISMO DE SEGURO OU, AINDA, DO FUNDO GARANTIDOR DE CRÉDITOS FGC.
        </div>
        <div>
            LEIA O FORMULÁRIO DE INFORMAÇÕES COMPLEMENTARES, O PROSPECTO, A LÂMINA DE INFORMAÇÕES ESSENCIAIS E O REGULAMENTO ANTES DE APLICAR.
        </div>
        <div>
            Verifique se os fundos utilizam estratégia com derivativos como parte integrante de suas políticas de investimento.
            Tais estratégias podem resultar em perdas patrimoniais para seus cotistas,
            podendo inclusive acarretar perdas superiores ao capital aplicado e a consquente obrigação do cotista de aportar recursos adicionais para cobrir os prejuízos dos fundos.
            Os fundos multimercado e fundos de ações podem estar expostos à significativa concentração em ativos de poucos emissores, com os riscos daí recorrentes.
            Não há garantia de que os fundos multimercados e renda fixa terão o tratamento tributário para fundos de longo prazo.
        </div>
        <div>
            Os fundos de crédito privado estão sujeitos a risco de perda substancial de seu patrimônio líquido em caso de eventos que acarretem o não pagamento dos ativos integrantes
            de sua carteira. Ao investidor cabe a responsabilidade de se informar sobre eventuais riscos previamente à tomada de decisão sobre investimentos.
            Este material não deve substituir o julgamento independente dos investidores. Alguns fundos estão autorizados a realizar aplicações em ativos financeiros
            no exterior ou que aplicam em fundos que estão autorizados a realizar tais aplicações.
        </div>
    </div>
    <div class="copyright">
        <span class="bold">Todos os direitos reservados. </span> 
        <span>Copyright 2020. Simétrica Investimentos.</span>
        <a href="https://br.freepik.com/">Imagens, Uso e Crédito Freepik </a>
    </div>
</div>