import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConteudoService } from '../../../services/conteudo.service';
import { MensagemService } from '../../../services/mensagem.service';

@Component({
    selector: "app-outras-informacoes",
    templateUrl: "./outras-informacoes.component.html",
    styleUrls: ["./outras-informacoes.component.css"]
})
export class OutrasInformacoesComponent implements OnInit {
    constructor(private formBuilder: FormBuilder,private conteudoService:ConteudoService,private mensagemService:MensagemService) { }
    formTextos: FormGroup;

    ngOnInit() {
        this.formTextos = this.formBuilder.group({
            dataInicio: this.formBuilder.control(null),
            secao1Titulo: this.formBuilder.control(null),
            secao1Texto: this.formBuilder.control(null),
            secao2Titulo: this.formBuilder.control(null),
            secao2Texto: this.formBuilder.control(null),
            secao3Titulo: this.formBuilder.control(null),
            secao3Texto: this.formBuilder.control(null)
        });

        this.carregarDados();
    }

    atualizar() {
        this.conteudoService.atualizar(this.formTextos.value).subscribe(() => {
            this.mensagemService.exibirSucesso("Texto Informativos", "Informações atualizadas com sucesso");
        });
    }

    private carregarDados() {
        this.conteudoService.obter().subscribe(conteudo => {
            if (conteudo) {
                this.formTextos.setValue(conteudo);
            }
        });
    }
}