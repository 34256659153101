import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Conteudo } from '../models/conteudo.model';
import { Observable } from 'rxjs';

@Injectable()
export class ConteudoService {
    constructor(private http: HttpClient) { }

    atualizar(conteudo: Conteudo): Observable<any> {
        return this.http.post<any>("api/conteudo/atualizar", conteudo);
    }

    obter(): Observable<Conteudo> {
        return this.http.get<Conteudo>("api/conteudo");
    }
}