import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, Injector, NgZone } from "@angular/core";
import { TipoMensagem, Mensagem, MensagemService } from './services/mensagem.service';

@Injectable()
export class ApplicationErrorHandler extends ErrorHandler {
    constructor(private injector: Injector, private zone: NgZone) { super() }

    handleError(errorResponse: HttpErrorResponse | any) {
        if (errorResponse instanceof HttpErrorResponse) {
            this.zone.run(() => {
                let message: string = "";
                switch (errorResponse.status) {
                    case 400:
                        message = "Ocorreu um erro ao processar sua requisição.";
                        let tipoMensagem: TipoMensagem = TipoMensagem.erro;
                        let mensagem: Mensagem = {
                            origem: "Erro na Requisição",
                            texto: message,
                            tipo: tipoMensagem
                        };
                        this.injector.get(MensagemService).exibir(mensagem);
                        break;
                    case 401:
                        this.injector.get(MensagemService).exibirErro("Erro na Requisição", "Você não tem permissão para utilizar este recurso.");
                        break;
                    case 500:
                        this.injector.get(MensagemService).exibirErro("Erro na Requisição", "Ocorreu um erro ao processar sua requisição.");
                        break;
                }
            });
        }
        super.handleError(errorResponse);
    }
}