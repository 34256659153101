import { Injectable } from "@angular/core";
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';

@Injectable()
export class ScrollService {
    constructor(private scrollToService: ScrollToService) { }

    irPara(destino: string) {
        this.scrollToService.scrollTo({ target: destino });
    }

    verificarSeElementoVisivel(elemento: HTMLElement, elementoPai: HTMLElement): boolean {
        if (!elemento || !elementoPai) {
            return false;
        }

        let alturaElementoPai: number = elementoPai.offsetTop;
        let posicaoRolagemInicial: number = elementoPai.scrollTop;
        let posicaoRolagemFinal: number = posicaoRolagemInicial + elementoPai.offsetHeight;

        let posicaoElementoInicial: number = elemento.offsetTop;
        let posicaoElementoFinal: number = posicaoElementoInicial + elemento.offsetHeight;

        let topoElementoValidoInicio: boolean = (alturaElementoPai + posicaoRolagemInicial) <= posicaoElementoInicial;
        let topoElementoValidoFim: boolean = posicaoElementoInicial < posicaoRolagemFinal;
        if (topoElementoValidoInicio && topoElementoValidoFim) {
            return true;
        }

        if (posicaoElementoInicial < (alturaElementoPai + posicaoRolagemInicial) && posicaoElementoFinal > posicaoRolagemFinal) {
            return true;
        }
        return false;
    }
}