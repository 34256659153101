import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { TokenInfo } from './models/usuario.model';
import { UsuarioService } from './services/usuario.service';

@Injectable()
export class AcessosInterceptor implements HttpInterceptor {
    private excecoes: string[] = ["https://maps.googleapis.com/maps/api/geocode"];

    atualizandoToken: boolean = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(private injetor: Injector) { }

    addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        return req.clone({ setHeaders: { Authorization: `Bearer ${token}` } })
    }
    handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const autenticacaoService = this.injetor.get(UsuarioService);
        if (!this.atualizandoToken) {
            this.atualizandoToken = true;
            this.tokenSubject.next(null);

            return autenticacaoService.renovarToken().pipe(switchMap((token: TokenInfo) => {
                if (token && token.token) {
                    this.tokenSubject.next(token.token);
                    return next.handle(this.addToken(req, token.token));
                }
                autenticacaoService.sair();
                return Observable.throw(null);
            }), catchError(() => {
                autenticacaoService.sair();
                return Observable.throw(null);
            }), finalize(() => {
                this.atualizandoToken = false;
            }));
        }
        else {
            return this.tokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(this.addToken(req, token))
                }));
        }
    }
    handle400Error(error: HttpErrorResponse): Observable<HttpEvent<any>> {
        const autenticacaoService = this.injetor.get(UsuarioService);
        if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
            autenticacaoService.sair();
        }
        return throwError(error);
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const autenticacaoService = this.injetor.get(UsuarioService);
        if (autenticacaoService.usuarioAutenticado() && !this.validarExcecao(request.url)) {
            const authRequest = this.addToken(request, autenticacaoService.obterCookieToken().token);
            return next.handle(authRequest).pipe(catchError(error => {
                if (error instanceof HttpErrorResponse) {
                    switch ((<HttpErrorResponse>error).status) {
                        case 400:
                            return this.handle400Error(error);
                        case 401:
                            return this.handle401Error(request, next);
                        case 403:
                            return throwError(error);
                    }
                }
                else {
                    return throwError(error);
                }
            }));
        }
        else {
            return next.handle(request);
        }
    }

    private validarExcecao(url: string): boolean {
        let excecao = this.excecoes.find(x => url.indexOf(x) >= 0);
        if (excecao) {
            return true;
        }

        if (this.verificarUrlStreaming(url)) {
            return true;
        }

        return false;
    }
    private verificarUrlStreaming(url: string): boolean {
        if (url && url.length > 5) {
            let finalUrl: string = url.substring(url.length - 5);
            if (finalUrl.toLowerCase() === ".m3u8") {
                return true;
            }
        }
        return false;
    }
}