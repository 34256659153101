import { Component } from "@angular/core";
import { UsuarioService } from '../../services/usuario.service';

enum ConteudoAdmin {
    Fundos,
    Indices,
    Mensal,
    Outros
}

@Component({
    selector: "app-admin",
    templateUrl: "./admin.component.html",
    styleUrls: ["./admin.component.css"]
})
export class AdminComponent {
    constructor(private usuarioService: UsuarioService) { }

    conteudoSelecionado: ConteudoAdmin;

    sair() {
        this.usuarioService.sair();
    }
}