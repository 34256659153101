import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { RentabilidadeAcumuladaFundo } from '../models/rentabilidade-acumulada-fundo.model';

@Injectable()
export class RentabilidadeAcumuladaFundoService {
    constructor(private http: HttpClient) { }

    inserir(parametro: RentabilidadeAcumuladaFundo): Observable<any> {
        return this.http.post<any>("api/rentabilidade/acumulada/fundo/inserir", parametro);
    }

    atualizar(parametro: RentabilidadeAcumuladaFundo): Observable<any> {
        return this.http.post<any>("api/rentabilidade/acumulada/fundo/atualizar", parametro);
    }

    remover(id: number): Observable<any> {
        return this.http.post<any>("api/rentabilidade/acumulada/fundo/remover", { id: id });
    }

    listar(): Observable<RentabilidadeAcumuladaFundo[]> {
        return this.http.get<RentabilidadeAcumuladaFundo[]>("api/rentabilidade/acumulada/fundo");
    }
}