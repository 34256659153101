import { Component, OnInit } from '@angular/core';
import { timer } from 'rxjs';
import { switchMap, tap } from "rxjs/operators";
import { MensagemService, TipoMensagem } from '../../services/mensagem.service';


@Component({
    selector: 'app-mensagem',
    templateUrl: './mensagem.component.html',
    styleUrls: ['./mensagem.component.css']
})
export class MensagemComponent implements OnInit {
    intervalo: any;
    larguraBarra: number = 100;
    mensagem: string = "";
    visivel: boolean = false;
    tipo: TipoMensagem;

    constructor(private mensagemService: MensagemService) { }

    ngOnInit() {
        this.mensagemService.mensagemSolicitada.pipe(tap(m => {
            this.mensagem = m.texto;
            this.visivel = true;
            this.tipo = m.tipo;
            this.iniciarContagem();
        }), switchMap(() => timer(5000))).subscribe(() => this.fechar());
    }

    fechar() {
        this.visivel = false;
        if (this.intervalo) {
            window.clearInterval(this.intervalo);
            this.intervalo = undefined;
        }
    }

    obterIcone(): string {
        return `./assets/images/svg/icon_msg_${this.tipo}.svg`;
    }

    private iniciarContagem() {
        this.larguraBarra = 100;
        if (this.intervalo) {
            window.clearInterval(this.intervalo);
            this.intervalo = undefined;
        }

        this.intervalo = window.setInterval(() => {
            this.larguraBarra -= 1;
        }, 50);
    }
}
