<div class="grid-admin">
    <div class="cabecalho-grid">
        <div class="coluna"
             [class.esquerda]="col.esquerda"
             [class.direita]="col.direita"
             [class.x2]="col.grande"
             [class.centro]="col.centro"
             *ngFor="let col of colunas">{{col.descricao}}</div>
        <div class="coluna"></div>
    </div>
    <div class="linha-grid" [class.destaque]="item.destaque" *ngFor="let item of dados">
        <form *ngIf="item.form" [formGroup]="item.form" (submit)="confirmarAlteracoes(item)">
            <div class="coluna"
                 [class.esquerda]="col.esquerda"
                 [class.direita]="col.direita"
                 [class.x2]="col.grande"
                 [class.bold]="col.negrito"
                 [class.centro]="col.centro"
                 [class.icone-destaque]="destaque(col)"
                 *ngFor="let col of colunas">
                <input *ngIf="!destaque(col) && col.campo" type="text" [formControlName]="col.campo" class="editar" [hidden]="!item.editando" />
                <span *ngIf="!destaque(col) && col.campo" [hidden]="item.editando">{{item[col.campo]}}</span>
                <input *ngIf="destaque(col) && col.campo" [formControlName]="col.campo" type="checkbox" [hidden]="!item.editando" />
                <svg-icon *ngIf="destaque(col) && col.campo && !item.editando" [class.check]="item[col.campo]===true" [src]="obterIconeDestaque(item)"></svg-icon>
            </div>
            <div class="coluna acao editando">
                <button type="submit" class="botao ok" title="Gravar" *ngIf="item.editando">
                    <svg-icon src="/assets/images/svg/icon_check.svg"></svg-icon>
                </button>
                <button type="button" class="botao cancelar" title="Cancelar" *ngIf="item.editando" (click)="cancelarAlteracoes(item)">
                    <svg-icon src="/assets/images/svg/icon_ban.svg"></svg-icon>
                </button>
                <button type="button" class="botao remover" title="Remover" *ngIf="!item.editando" (click)="remover(item)">
                    <svg-icon src="/assets/images/svg/icon_trash.svg"></svg-icon>
                </button>
                <button type="button" class="botao editar" title="Editar" *ngIf="!item.editando" (click)="editar(item)">
                    <svg-icon src="/assets/images/svg/icon_edit.svg"></svg-icon>
                </button>
            </div>
        </form>
    </div>
</div>
