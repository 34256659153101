<div class="site-base">
    <app-cabecalho id="cabecalho" [menus]="menus" [(menuSelecionado)]="menuSelecionado" (navegar)="navegar($event)"></app-cabecalho>
    <div class="conteudo-site" #divBase (scroll)="onScroll()" [style.height.px]="obterAlturaConteudo()">
        <app-home></app-home>
        <app-fundos></app-fundos>
        <app-simetrica></app-simetrica>
        <app-documentos></app-documentos>
        <app-contato></app-contato>
        <app-rodape></app-rodape>
    </div>
    <app-mensagem></app-mensagem>
</div>