import { Component, OnInit } from "@angular/core";
import { RentabilidadeAcumuladaFundo } from '../../../../../models/rentabilidade-acumulada-fundo.model';
import { RentabilidadeAcumuladaFundoService } from '../../../../../services/rentabilidade-acumulada-fundo.service';

@Component({
    selector: "app-rentabilidade-acumulada-fundo",
    templateUrl: "./rentabilidade-acumulada-fundo.component.html"
})
export class RentabilidadeAcumuladaFundoComponent implements OnInit {
    constructor(private rentService: RentabilidadeAcumuladaFundoService) { }

    dados: RentabilidadeAcumuladaFundo[];

    ngOnInit() {
        this.rentService.listar().subscribe(dados => this.dados = dados);
    }
}