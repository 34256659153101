<div class="base-login">
    <div class="login">
        <a [routerLink]="['/']">
            <div class="logotipo-login">
                <img src="./assets/images/png/logo_i.png" alt="logo" class="img" />
                <img src="./assets/images/png/logotipo.png" alt="logotipo" class="tipo" />
            </div>
        </a>
        <div class="titulo-login">
            Login
        </div>
        <div class="sub-titulo-login">
            Para editar as informações, primeiro identifique-se
        </div>
        <form [formGroup]="formLogin" (submit)="autenticar()">
            <div>
                <input formControlName="login" type="text" placeholder="Login *" />
            </div>
            <div>
                <input formControlName="senha" type="password" placeholder="Senha *" />
            </div>
            <div class="acao-login">
                <button type="submit" class="botao ok" [disabled]="formLogin.invalid">
                    <svg-icon src="/assets/images/svg/icon_check.svg"></svg-icon>
                </button>
            </div>
            <div class="mensagem-login" *ngIf="acessoNegado">
                Acesso negado
            </div>
        </form>
    </div>
</div>