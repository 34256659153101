import { Component, OnInit } from "@angular/core";
import { RentabilidadeMensal } from '../../../../models/rentabilidade-mensal.model';
import { RentabilidadeMensalService } from '../../../../services/rentabilidade-mensal.service';

@Component({
    selector: "app-rentabilidade-mensal",
    templateUrl: "./rentabilidade-mensal.component.html"
})
export class RentabilidadeMensalComponent implements OnInit {
    constructor(private rentService: RentabilidadeMensalService) { }

    dados: RentabilidadeMensal[];

    ngOnInit() {
        this.rentService.listar().subscribe(dados => this.dados = dados);
    }
}