<div class="info-localizacao">
    <div class="titulo-info-localizacao">
        Informações / Localização
    </div>
    <div class="endereco">
        <div>
            Avenida Brigadeiro Luis Antônio 2543 Conj 92 – Jardim Paulista - São Paulo - SP
        </div>
        <div>
            CEP: 01401-000
        </div>
        <div>
            Telefone: (11) 97193-5475
        </div>
    </div>
    <div class="email">
        <a href="mailto:simetrica@simetricainvest.com.br">simetrica@simetricainvest.com.br</a>
    </div>
    <a href="https://www.google.com/maps/place/Av.+Brigadeiro+Lu%C3%ADs+Ant%C3%B4nio,+2543+-+Jardim+Paulista,+S%C3%A3o+Paulo+-+SP,+01401-000/@-23.5679981,-46.6555407,15z/data=!4m6!3m5!1s0x94ce59bf643a8ecb:0xffbb2cf333eca3a1!8m2!3d-23.5697907!4d-46.6506904!16s%2Fg%2F11c2g8nmh3?entry=ttu&g_ep=EgoyMDI0MTAxMy4wIKXMDSoASAFQAw%3D%3D" target="_blank">
        <div class="imagem-mapa">
            <img src="/assets/images/png/img_mapa_gmaps.jpg" alt="mapa" />
        </div>
        <div class="google-maps">
            <div class="icone-google"><img src="/assets/images/png/icon_google.png" alt="mapa" /></div>
            <div class="texto-google">
                Clique e abra o Google Maps
            </div>
        </div>
    </a>
</div>