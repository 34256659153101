import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ItemMenuComponent } from '../menu/item-menu.component';
import { MenuComponent } from '../menu/menu.component';

@Component({
    selector: "app-menu-mobile",
    templateUrl: "./menu-mobile.component.html",
    styleUrls: ["./menu-mobile.component.css"]
})
export class MenuMobileComponent extends MenuComponent {
    @Input() exibir: boolean = false;
    @Output() fechar: EventEmitter<void> = new EventEmitter<void>();

    fecharMenu() {
        this.fechar.emit();
    }

    selecionarMenu(item: ItemMenuComponent, index: number) {
        this.selecionar(item, index);
        this.fechar.emit();
    }
}