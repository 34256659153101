import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TokenInfo, Usuario } from '../models/usuario.model';
import { AppCookieService } from './app-cookie.service';
import { Router } from '@angular/router';

@Injectable()
export class UsuarioService {
    constructor(private http: HttpClient, private cookies: AppCookieService,private router:Router) { }

    private _solicitarAutenticacao: Subject<void> = new Subject<void>();
    private _usuarioAutenticou: Subject<void> = new Subject<void>();
    aoSolicitarAutenticacao: Observable<void> = this._solicitarAutenticacao.asObservable();
    usuarioAutenticou: Observable<void> = this._usuarioAutenticou.asObservable();

    autenticar(usuario: Usuario): Observable<TokenInfo> {
        return this.http.post<TokenInfo>("api/autenticar", usuario).pipe(tap(tokenInfo => {
            this.gravarCookieToken(tokenInfo);
            this._usuarioAutenticou.next();
        }));
    }
    gravarCookieToken(token: TokenInfo) {
        this.cookies.gravarCookieToken(token);
    }
    obterCookieToken(): TokenInfo {
        return this.cookies.obterCookieToken();
    }
    renovarToken(): Observable<TokenInfo> {
        if (!this.usuarioAutenticado()) {
            return null;
        }
        var tokenInfo = this.obterCookieToken();
        return this.http.post<TokenInfo>("api/autenticacao/renovar", tokenInfo).pipe(tap(ti => {
            this.gravarCookieToken(ti);
        }));
    }
    sair() {
        this.cookies.removerCookieToken();
        this.router.navigateByUrl("login");
    }
    solicitarAutenticacao() {
        this._solicitarAutenticacao.next();
    }
    usuarioAutenticado(): boolean {
        let token: TokenInfo = this.obterCookieToken();
        if (!token) {
            return false;
        }
        let validade: Date = new Date(token.dataValidade);

        if (token && validade > new Date()) {
            return true;
        }
        return false;
    }
}