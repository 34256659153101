import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ItemGrid } from '../../../models/grid.model';
import { RentabilidadeService } from '../../../services/rentabilidade.service';
import { GridColumnAdminComponent } from './grid-column-admin.component';

@Component({
    selector: "app-grid-admin",
    templateUrl: "./grid-admin.component.html"
})
export class GridAdminComponent implements AfterContentInit {
    constructor(private formBuilder: FormBuilder, private rentService: RentabilidadeService) { }

    @ContentChildren(GridColumnAdminComponent) colunas: QueryList<GridColumnAdminComponent>;
    @Input() dados: ItemGrid[];
    @Output() atualizar: EventEmitter<any> = new EventEmitter<any>();
    @Output() excluir: EventEmitter<any> = new EventEmitter<any>();

    forms: FormGroup[];

    ngAfterContentInit() {
        if (this.dados && this.dados.length > 0) {
            if (this.colunas && this.colunas.length > 0) {
                this.dados.forEach(d => this.criarForm(d));
            }
        }

        this.rentService.adicionar.subscribe(item => this.criarForm(item));
    }

    cancelarAlteracoes(item: ItemGrid) {
        let form: FormGroup = item.form;
        this.colunas.forEach(c => {
            form.controls[c.campo].setValue(item[c.campo]);
        });

        form.markAsUntouched();
        form.markAsPristine();

        item.editando = false;

        if (item.id === 0) {
            this.remover(item);
        }
    }

    confirmarAlteracoes(item: ItemGrid) {
        this.atualizar.emit(item.form.value);
        item.editando = false;
    }

    destaque(coluna: GridColumnAdminComponent): boolean {
        return coluna.campo === "destaque";
    }

    editar(item: ItemGrid) {
        item.editando = true;
    }

    obterIconeDestaque(item: ItemGrid): string {
        let status: string = item.destaque ? "check" : "times";
        return `/assets/images/svg/icon_${status}.svg`;
    }

    remover(item: ItemGrid) {
        this.excluir.emit(item);
    }

    private criarForm(item: ItemGrid) {
        item.form = this.formBuilder.group({ id: this.formBuilder.control(item.id) });

        this.colunas.forEach(c => {
            if (c.campo) {
                item.form.addControl(c.campo, this.formBuilder.control(item[c.campo]));
            }
        });
    }
}
