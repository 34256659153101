import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ScrollService } from '../../services/scroll.service';
import { UsuarioService } from '../../services/usuario.service';
import { ItemMenu } from '../cabecalho/menu/item-menu.component';
import { EventoMenuSelecionado } from '../cabecalho/menu/menu.component';

@Component({
    selector: "app-index",
    templateUrl: "./index.component.html",
    styleUrls: ["./index.component.css"]
})
export class IndexComponent implements OnInit {
    constructor(private scrollService: ScrollService, private usuarioService: UsuarioService) { }

    @ViewChild("divBase") divBaseRef: ElementRef;
    divBase: HTMLDivElement;

    ngOnInit() {
        if (this.divBaseRef) {
            this.divBase = this.divBaseRef.nativeElement;
        }
    }

    menus: ItemMenu[] = [
        { descricao: "Home", nomeTag: "home" },
        { descricao: "Fundos", nomeTag: "fundos" },
        { descricao: "A Simétrica", nomeTag: "simetrica" },
        { descricao: "Documentos", nomeTag: "documentos" },
        { descricao: "Contato", nomeTag: "contato" }
    ];

    menuSelecionado: ItemMenu = this.menus[0];

    navegar(evento: EventoMenuSelecionado) {
        this.menuSelecionado = evento.item.dados;
        this.scrollService.irPara(this.menuSelecionado.nomeTag);
    }

    obterAlturaConteudo(): number {
        let cabecalho: HTMLDivElement = document.getElementById("cabecalho-site") as HTMLDivElement;
        let altura: number = cabecalho.offsetHeight;
        let resultado = document.body.offsetHeight - altura;
        return resultado;
    }

    onScroll() {
        let itensVisiveis: ItemMenu[] = [];
        this.menus.forEach(m => {
            let elemento: HTMLElement = document.getElementById(m.nomeTag);
            if (elemento) {
                let elementoVisivel: boolean = this.scrollService.verificarSeElementoVisivel(elemento, this.divBaseRef.nativeElement);
                if (elementoVisivel) {
                    itensVisiveis.push(m);
                }
            }
        });

        if (itensVisiveis && itensVisiveis.length > 0) {
            this.menuSelecionado = itensVisiveis[0];
        }
    }
}