import { Component, OnInit } from "@angular/core";
import { RentabilidadeAcumuladaFundo } from '../../../../models/rentabilidade-acumulada-fundo.model';
import { RentabilidadeAcumuladaFundoService } from '../../../../services/rentabilidade-acumulada-fundo.service';
import { RentabilidadeService } from '../../../../services/rentabilidade.service';

@Component({
    selector: "app-rentabilidade-acumulada-fundo-admin",
    templateUrl: "./rentabilidade-acumulada-fundo.component.html"
})
export class RentabilidadeAcumuladaFundoAdminComponent implements OnInit {
    constructor(private rentService: RentabilidadeAcumuladaFundoService, private rentabilidadeService: RentabilidadeService) { }
    dados: RentabilidadeAcumuladaFundo[];

    ngOnInit() {
        this.carregarDados();
    }

    adicionar() {
        let item: RentabilidadeAcumuladaFundo = { id: 0, editando: true, destaque: false };
        if (!this.dados) {
            this.dados = [];
        }
        this.dados.push(item);
        this.rentabilidadeService.adicionarItem(item);
    };

    confirmarAlteracoes(item: RentabilidadeAcumuladaFundo) {
        if (item.id === 0) {
            this.rentService.inserir(item).subscribe(() => {
                this.dados = null;
                this.carregarDados();
            });
        }
        else {
            this.rentService.atualizar(item).subscribe(() => {
                this.dados = null;
                this.carregarDados();
            });
        }
    }

    remover(item: RentabilidadeAcumuladaFundo) {
        this.rentService.remover(item.id).subscribe(() => {
            this.dados = null;
            this.carregarDados();
        });
    }

    private carregarDados() {
        this.rentService.listar().subscribe(dados => this.dados = dados);
    }
}