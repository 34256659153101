import { Component, ContentChildren, EventEmitter, Output, QueryList, Input, AfterContentInit } from "@angular/core";
import { ItemMenuComponent } from './item-menu.component';

export interface EventoMenuSelecionado {
    indice: number;
    item: ItemMenuComponent;
}

@Component({
    selector: "app-menu",
    templateUrl: "./menu.component.html",
    styleUrls: ["./menu.component.css"]
})
export class MenuComponent {
    @ContentChildren(ItemMenuComponent) itens: QueryList<ItemMenuComponent>;
    @Output() menuSelecionado: EventEmitter<EventoMenuSelecionado> = new EventEmitter<EventoMenuSelecionado>();

    selecionar(item: ItemMenuComponent, index: number) {
        if (item.semClick) {
            return;
        }
        let evento: EventoMenuSelecionado = { item: item, indice: index };
        this.menuSelecionado.emit(evento);
    }
}