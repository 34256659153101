<div class="conteudo-rentabilidade editar">
    <div class="titulo-edicao">
        Rentabilidade Acumulada - Indices
    </div>
    <div class="edicao">
        <button type="button" class="botao adicionar" title="Adicionar novo indice" (click)="adicionar()">
            <svg-icon src="/assets/images/svg/icon_add.svg"></svg-icon>
        </button>
    </div>
    <app-grid-admin *ngIf="dados && dados.length > 0" [dados]="dados" (atualizar)="confirmarAlteracoes($event)" (excluir)="remover($event)">
        <app-grid-column-admin campo="indice" descricao="Índices" [grande]="true" [esquerda]="true" [negrito]="true"></app-grid-column-admin>
        <app-grid-column-admin campo="data" descricao="Data"></app-grid-column-admin>
        <app-grid-column-admin></app-grid-column-admin>
        <app-grid-column-admin campo="dia" descricao="Dia"></app-grid-column-admin>
        <app-grid-column-admin campo="mes" descricao="Mês"></app-grid-column-admin>
        <app-grid-column-admin campo="ano" descricao="Ano"></app-grid-column-admin>
        <app-grid-column-admin campo="dozeM" descricao="12M"></app-grid-column-admin>
        <app-grid-column-admin campo="destaque" descricao="Destaque"></app-grid-column-admin>
        <app-grid-column-admin></app-grid-column-admin>
        <app-grid-column-admin></app-grid-column-admin>
        <app-grid-column-admin></app-grid-column-admin>
        <app-grid-column-admin [grande]="true" [centro]="true"></app-grid-column-admin>
    </app-grid-admin>
</div>