<div class="conteudo-rentabilidade">
    <app-grid *ngIf="dados && dados.length > 0" [dados]="dados">
        <app-grid-column campo="ano" descricao="Ano" [negrito]="true" [esquerda]="true"></app-grid-column>
        <app-grid-column campo="fundo" descricao="Fundo" [grande]="true" [esquerda]="true" [negrito]="true"></app-grid-column>
        <app-grid-column campo="janeiro" descricao="Jan"></app-grid-column>
        <app-grid-column campo="fevereiro" descricao="Fev"></app-grid-column>
        <app-grid-column campo="marco" descricao="Mar"></app-grid-column>
        <app-grid-column campo="abril" descricao="Abr"></app-grid-column>
        <app-grid-column campo="maio" descricao="Mai"></app-grid-column>
        <app-grid-column campo="junho" descricao="Jun"></app-grid-column>
        <app-grid-column campo="julho" descricao="Jul"></app-grid-column>
        <app-grid-column campo="agosto" descricao="Ago"></app-grid-column>
        <app-grid-column campo="setembro" descricao="Set"></app-grid-column>
        <app-grid-column campo="outubro" descricao="Out"></app-grid-column>
        <app-grid-column campo="novembro" descricao="Nov"></app-grid-column>
        <app-grid-column campo="dezembro" descricao="Dez"></app-grid-column>
        <app-grid-column campo="total" descricao="Ano" [negrito]="true"></app-grid-column>
    </app-grid>
</div>