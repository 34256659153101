import { Injectable } from "@angular/core";
import { Subject, Observable } from 'rxjs';

@Injectable()
export class RentabilidadeService {
    private _adicionar: Subject<any> = new Subject<any>();
    adicionar: Observable<any> = this._adicionar.asObservable();

    adicionarItem(item: any) {
        this._adicionar.next(item);
    }
}