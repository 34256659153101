<div class="fundos" id="fundos">
    <div class="titulo-fundos">
        Fundos de Investimento
    </div>
    <div class="texto-fundos">
        O SIMÉTRICA BR FIA – BDR NÍVEL I é um fundo de ações destinado a investidores em geral que buscam obter crescimento patrimonial
        no médio e longo prazo. O fundo busca manter uma carteira comprada em ações, resultante de um processo de
        seleção fundamentalista e análise proprietária.
    </div>
    <div class="links-fundos">
        <a href="/assets/docs/Relatorio-Mensal.pdf" download>
            <div class="item-link-fundos">
                <div class="icone-link-fundos"><img src="/assets/images/png/icon_relat_mensal.png" alt="relatorio_mensal" /></div>
                <div class="texto-link-fundos">Relatório Mensal</div>
            </div>
        </a>
        <a href="/assets/docs/Regulamento-Simetrica-BR-FIA.pdf" download>
            <div class="item-link-fundos">
                <div class="icone-link-fundos"><img src="/assets/images/png/icon_regulamento.png" alt="regulamento" /></div>
                <div class="texto-link-fundos">Regulamento</div>
            </div>
        </a>
        <!--<a href="/assets/docs/Informacoes-Complementares.pdf" download>
            <div class="item-link-fundos">
                <div class="icone-link-fundos"><img src="/assets/images/png/icon_regulamento.png" alt="info_complementares" /></div>
                <div class="texto-link-fundos">Informações Complementares</div>
            </div>
        </a>-->
    </div>
    <!-- <div class="invista-aqui-fundos">
        <app-invista-aqui [bordaEscura]="true"></app-invista-aqui>
    </div> -->
</div>