import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { GridAdminComponent } from "./componentes/grid-admin/grid-admin.component";
import { GridColumnAdminComponent } from './componentes/grid-admin/grid-column-admin.component';
import { GridColumnComponent } from './componentes/grid/grid-column.component';
import { GridComponent } from './componentes/grid/grid.component';
//import { InvistaAquiComponent } from './componentes/invista-aqui/invista-aqui.component';
@NgModule({
    declarations: [
        GridColumnComponent,
        GridColumnAdminComponent,
        GridComponent,
        GridAdminComponent,
        //InvistaAquiComponent
    ],
    imports: [
        AngularSvgIconModule.forRoot(),
        BrowserAnimationsModule,
        BrowserModule,
        FormsModule,
  ReactiveFormsModule
    ],
    exports: [
        GridColumnComponent,
        GridColumnAdminComponent,
        GridComponent,
        GridAdminComponent,
        //InvistaAquiComponent
    ],
    bootstrap: [CommonModule]
})
export class CommonModule { }