<div class="conteudo-rentabilidade editar">
    <div class="titulo-edicao">
        Rentabilidade Mensal
    </div>
    <div class="edicao">
        <button type="button" class="botao adicionar" title="Adicionar novo ano" (click)="adicionar()">
            <svg-icon src="/assets/images/svg/icon_add.svg"></svg-icon>
        </button>
    </div>
    <app-grid-admin *ngIf="dados && dados.length > 0" [dados]="dados" (atualizar)="confirmarAlteracoes($event)" (excluir)="remover($event)">
        <app-grid-column-admin campo="ano" descricao="Ano" [negrito]="true" [esquerda]="true"></app-grid-column-admin>
        <app-grid-column-admin campo="fundo" descricao="Fundo" [grande]="true" [esquerda]="true" [negrito]="true"></app-grid-column-admin>
        <app-grid-column-admin campo="janeiro" descricao="Jan"></app-grid-column-admin>
        <app-grid-column-admin campo="fevereiro" descricao="Fev"></app-grid-column-admin>
        <app-grid-column-admin campo="marco" descricao="Mar"></app-grid-column-admin>
        <app-grid-column-admin campo="abril" descricao="Abr"></app-grid-column-admin>
        <app-grid-column-admin campo="maio" descricao="Mai"></app-grid-column-admin>
        <app-grid-column-admin campo="junho" descricao="Jun"></app-grid-column-admin>
        <app-grid-column-admin campo="julho" descricao="Jul"></app-grid-column-admin>
        <app-grid-column-admin campo="agosto" descricao="Ago"></app-grid-column-admin>
        <app-grid-column-admin campo="setembro" descricao="Set"></app-grid-column-admin>
        <app-grid-column-admin campo="outubro" descricao="Out"></app-grid-column-admin>
        <app-grid-column-admin campo="novembro" descricao="Nov"></app-grid-column-admin>
        <app-grid-column-admin campo="dezembro" descricao="Dez"></app-grid-column-admin>
        <app-grid-column-admin campo="total" [negrito]="true"></app-grid-column-admin>
        <app-grid-column-admin campo="destaque" descricao="Destaque"></app-grid-column-admin>
    </app-grid-admin>
</div>