import { Component, Input, Output, EventEmitter } from "@angular/core";
import { EventoMenuSelecionado } from './menu/menu.component';
import { ItemMenu } from './menu/item-menu.component';

@Component({
    selector: "app-cabecalho",
    templateUrl: "./cabecalho.component.html",
    styleUrls: ["./cabecalho.component.css"]
})
export class CabecalhoComponent {
    data: Date = new Date();
    @Input() menus: ItemMenu[];
    @Input() menuSelecionado: ItemMenu;
    @Input() mostrarMenu: boolean = true;
    @Output() navegar: EventEmitter<EventoMenuSelecionado> = new EventEmitter<EventoMenuSelecionado>();

    exibirMenuMobile: boolean = false;

    abrirMenu() {
        this.exibirMenuMobile = true;
    }

    fecharMenu() {
        this.exibirMenuMobile = false;
    }

    irPara(evento: EventoMenuSelecionado) {
        this.navegar.emit(evento);
    }
}