import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { enableProdMode, ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { AngularSvgIconModule } from "angular-svg-icon";
import { CookieService } from "ngx-cookie-service";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApplicationErrorHandler } from './app.error-handler';
import { AcessosInterceptor } from './app.http-interceptor';
import { CommonModule } from './common/common.module';
import { AppCookieService } from './services/app-cookie.service';
import { ContatoService } from './services/contato.service';
import { MensagemService } from './services/mensagem.service';
import { RentabilidadeAcumuladaFundoService } from './services/rentabilidade-acumulada-fundo.service';
import { RentabilidadeAcumuladaIndicesService } from './services/rentabilidade-acumulada-indices.service';
import { RentabilidadeMensalService } from './services/rentabilidade-mensal.service';
import { RentabilidadeService } from './services/rentabilidade.service';
import { ScrollService } from './services/scroll.service';
import { UsuarioService } from './services/usuario.service';
import { CabecalhoComponent } from './site/cabecalho/cabecalho.component';
import { ItemMenuComponent } from './site/cabecalho/menu/item-menu.component';
import { MenuComponent } from './site/cabecalho/menu/menu.component';
import { ContatoComponent } from './site/contato/contato.component';
import { FormContatoComponent } from './site/contato/form-contato/form-contato.component';
import { InfoLocalizacaoComponent } from './site/contato/info-localizacao/info-localizacao.component';
import { DocumentosComponent } from './site/documentos/documentos.component';
import { FundosComponent } from './site/fundos/fundos.component';
import { HomeComponent } from './site/home/home.component';
import { InfoHomeComponent } from './site/home/info-home/info-home.component';
import { RentabilidadeAcumuladaFundoComponent } from './site/home/rentabilidade/acumulada/fundo/rentabilidade-acumulada-fundo.component';
import { RentabilidadeAcumuladaIndicesComponent } from './site/home/rentabilidade/acumulada/indices/rentabilidade-acumulada-indices.component';
import { RentabilidadeAcumuladaComponent } from './site/home/rentabilidade/acumulada/rentabilidade-acumulada.component';
import { RentabilidadeMensalComponent } from './site/home/rentabilidade/mensal/rentabilidade-mensal.component';
import { IndexComponent } from './site/index/index.component';
import { RodapeComponent } from './site/rodape/rodape.component';
import { SimetricaComponent } from './site/simetrica/simetrica.component';
import { AdminComponent } from './site/admin/admin.component';
import { LoginComponent } from './site/admin/login/login.component';
import { AcessosGuard } from './acessos.guard';
import { RentabilidadeAcumuladaFundoAdminComponent } from './site/admin/rentabilidade/acumulada-fundo/rentabilidade-acumulada-fundo.component';
import { RentabilidadeAcumuladaIndicesAdminComponent } from './site/admin/rentabilidade/acumulada-indices/rentabilidade-acumulada-indices.component';
import { RentabilidadeMensalAdminComponent } from './site/admin/rentabilidade/mensal/rentabilidade-mensal.component';
import { OutrasInformacoesComponent } from './site/admin/outras-informacoes/outras-informacoes.component';
import { ConteudoService } from './services/conteudo.service';
import { MensagemComponent } from './site/mensagem/mensagem.component';
import { MenuMobileComponent } from './site/cabecalho/menu-mobile/menu-mobile.component';

registerLocaleData(localePt);
enableProdMode();

@NgModule({
    declarations: [
        AdminComponent,
        LoginComponent,
        AppComponent,
        CabecalhoComponent,
        ContatoComponent,
        DocumentosComponent,
        FormContatoComponent,
        FundosComponent,
        HomeComponent,
        IndexComponent,
        InfoHomeComponent,
        InfoLocalizacaoComponent,
        ItemMenuComponent,
        MenuComponent,
        MenuMobileComponent,
        MensagemComponent,
        OutrasInformacoesComponent,
        RentabilidadeAcumuladaComponent,
        RentabilidadeAcumuladaFundoComponent,
        RentabilidadeAcumuladaFundoAdminComponent,
        RentabilidadeAcumuladaIndicesComponent,
        RentabilidadeAcumuladaIndicesAdminComponent,
        RentabilidadeMensalComponent,
        RentabilidadeMensalAdminComponent,
        RodapeComponent,
        SimetricaComponent
    ],
    imports: [
        AngularSvgIconModule.forRoot(),
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        FormsModule,
        HttpClientModule,
        MatInputModule,
        ReactiveFormsModule,
        ScrollToModule.forRoot()
    ],
    providers: [
        AcessosGuard,
        AppCookieService,
        ContatoService,
        ConteudoService,
        CookieService,
        MensagemService,
        RentabilidadeAcumuladaFundoService,
        RentabilidadeAcumuladaIndicesService,
        RentabilidadeMensalService,
        RentabilidadeService,
        ScrollService,
        UsuarioService,
        { provide: LOCALE_ID, useValue: "pt-BR" },
        { provide: HTTP_INTERCEPTORS, useClass: AcessosInterceptor, multi: true },
        { provide: ErrorHandler, useClass: ApplicationErrorHandler }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
