import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { RentabilidadeAcumuladaIndices } from '../models/rentabilidade-acumulada-indices.model';
import { Observable } from 'rxjs';

@Injectable()
export class RentabilidadeAcumuladaIndicesService {
    constructor(private http: HttpClient) { }

    inserir(parametro: RentabilidadeAcumuladaIndices): Observable<any> {
        return this.http.post<any>("api/rentabilidade/acumulada/indices/inserir", parametro);
    }

    atualizar(parametro: RentabilidadeAcumuladaIndices): Observable<any> {
        return this.http.post<any>("api/rentabilidade/acumulada/indices/atualizar", parametro);
    }

    remover(id: number): Observable<any> {
        return this.http.post<any>("api/rentabilidade/acumulada/indices/remover", { id: id });
    }

    listar(): Observable<RentabilidadeAcumuladaIndices[]> {
        return this.http.get<RentabilidadeAcumuladaIndices[]>("api/rentabilidade/acumulada/indices");
    }
}