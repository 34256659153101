<div class="menu-mobile" [class.exibir]="exibir">
    <div class="fechar-menu">
        <svg-icon src="/assets/images/svg/icon_fechar_menu.svg" (click)="fecharMenu()"></svg-icon>
    </div>
    <div class="item-menu-mobile"
         *ngFor="let item of itens; let i = index"
         [class.selecionado]="item.selecionado"
         (click)="selecionarMenu(item,i)">
        <ng-template [ngTemplateOutlet]="item.conteudo"></ng-template>
    </div>
</div>