<div class="info-home">
    <div class="base-info-grande">
        <div class="linha-info">
            <div class="bloco-info">
                <div class="icone-bloco-info"><img src="/assets/images/png/icon_publico_alvo.png" alt="publico_alvo" /></div>
                <div class="titulo-bloco-info">Público Alvo</div>
                <div class="texto-bloco-info">Investidores em geral (pessoas físicas ou jurídicas) que buscam retornos reais em longo prazo por meio de investimentos em ações</div>
            </div>
            <div class="bloco-info">
                <div class="icone-bloco-info"><img src="/assets/images/png/icon_movimentacoes.png" alt="movimentacoes" /></div>
                <div class="titulo-bloco-info">Movimentações</div>
                <div class="texto-bloco-info">
                    <div>Aplicação mínima inicial R$1.000,00</div>
                    <div>Movimentação mínima R$1.000,00</div>
                </div>
            </div>
            <div class="bloco-info">
                <div class="icone-bloco-info"><img src="/assets/images/png/icon_tribut.png" alt="tributacoes" /></div>
                <div class="titulo-bloco-info">Tributação</div>
                <div class="texto-bloco-info">
                    <div>Fundo de Renda Variável (RV)</div>
                    <div>15% sobre o rendimento resgate</div>
                </div>
            </div>
        </div>
        <div class="linha-info">
            <div class="bloco-info">
                <div class="icone-bloco-info"><img src="/assets/images/png/icon_custos.png" alt="custos" /></div>
                <div class="titulo-bloco-info">Custos</div>
                <div class="texto-bloco-info">
                    <div class="sub-titulo-bloco-info">Taxa de Administração</div>
                    <div>2,00% ao ano (máxima 2,30% ao ano)</div>
                    <div class="sub-titulo-bloco-info">Taxa de Performance</div>
                    <div>20% sobre o que exceder o Ibovespa</div>
                </div>
            </div>
            <div class="bloco-info">
                <div class="icone-bloco-info"><img src="/assets/images/png/icon_info_adic.png" alt="informacoes_adicionais" /></div>
                <div class="titulo-bloco-info">Informações Adicionais</div>
                <div class="texto-bloco-info">
                    <div class="sub-titulo-bloco-info">Gestor</div>
                    <div>Simétrica Consultoria em Investimentos e Participações Ltda.</div>
                    <div class="sub-titulo-bloco-info">Administrador / Custodiante</div>
                    <div>Banco Daycoval S.A.</div>
                    <div class="sub-titulo-bloco-info">Auditor</div>
                    <div>Ernst & Young Auditores Independentes S/S</div>
                </div>
            </div>
            <div class="bloco-info">
                <div class="icone-bloco-info"><img src="/assets/images/png/icon_caract_fundo.png" alt="caracteristicas_fundo" /></div>
                <div class="titulo-bloco-info">Características do Fundo</div>
                <div class="texto-bloco-info">
                    <div class="sub-titulo-bloco-info">Início</div>
                    <div>08/08/2019</div>
                    <div class="sub-titulo-bloco-info">CNPJ</div>
                    <div>33.270.025/0001-64</div>
                    <div class="sub-titulo-bloco-info">Categoria Anbima</div>
                    <div>Ações Livre</div>
                    <div class="sub-titulo-bloco-info">Código Anbima</div>
                    <div>50939-6</div>
                </div>
            </div>
        </div>
    </div>
    <div class="base-info-pequena">
        <div class="bloco-info">
            <div class="icone-bloco-info"><img src="/assets/images/png/icon_regras_cotiza.png" alt="regras_cotizacao" /></div>
            <div class="titulo-bloco-info">Regras de Cotização</div>
            <div class="texto-bloco-info">
                <div class="sub-titulo-bloco-info">Aplicação</div>
                <div>Cotização em D+1 dia útil</div>
                <div class="sub-titulo-bloco-info">Resgate</div>
                <div>Cotização em D+15 dias corridos</div>
                <div class="sub-titulo-bloco-info">Liquidação</div>
                <div>Liquidação em D+2 dias úteis</div>
                <div class="sub-titulo-bloco-info">Horário de Movimentação</div>
                <div>Das 09:00 às 14:00</div>
            </div>
        </div>
    </div>
    <div class="citacao">
        <div class="icone-citacao">“</div>
        <div class="texto-citacao">Para nós, a avaliação dos riscos é tão importante quanto os retornos, uma relação retorno x risco favorável é fundamental para obtenção de resultados consistentes."</div>
    </div>
</div>