import { EventEmitter, Injectable } from "@angular/core";
import { Subject, Observable } from 'rxjs';

@Injectable()
export class MensagemService {
    _mensagemSolicitada: Subject<Mensagem> = new Subject<Mensagem>();
    mensagemSolicitada: Observable<Mensagem> = this._mensagemSolicitada.asObservable();

    exibir(mensagem: Mensagem) {
        this._mensagemSolicitada.next(mensagem);
    }
    exibirAlerta(origem: string, texto: string) {
        this.exibirMensagemTipo(origem, texto, TipoMensagem.alerta);
    }
    exibirErro(origem: string, texto: string) {
        this.exibirMensagemTipo(origem, texto, TipoMensagem.erro);
    }
    exibirSucesso(origem: string, texto: string) {
        this.exibirMensagemTipo(origem, texto, TipoMensagem.sucesso);
    }

    private exibirMensagemTipo(origem: string, texto: string, tipo: TipoMensagem) {
        let mensagem: Mensagem = {
            origem: origem,
            texto: texto,
            tipo: tipo
        };
        this.exibir(mensagem);
    }
}

export enum TipoMensagem {
    erro = "erro",
    sucesso = "sucesso",
    alerta = "alerta",
    info = "info"
}

export interface Mensagem {
    origem: string;
    texto: string;
    tipo: TipoMensagem;
    dataHora?: Date;
}