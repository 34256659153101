import { Component, OnInit } from "@angular/core";
import { RentabilidadeAcumuladaIndices } from '../../../../../models/rentabilidade-acumulada-indices.model';
import { RentabilidadeAcumuladaIndicesService } from '../../../../../services/rentabilidade-acumulada-indices.service';

@Component({
    selector: "app-rentabilidade-acumulada-indices",
    templateUrl: "./rentabilidade-acumulada-indices.component.html"
})
export class RentabilidadeAcumuladaIndicesComponent implements OnInit {
    constructor(private rentService: RentabilidadeAcumuladaIndicesService) { }

    dados: RentabilidadeAcumuladaIndices[];

    ngOnInit() {
        this.rentService.listar().subscribe(dados => this.dados = dados);
    }
}