<app-mensagem></app-mensagem>
<app-cabecalho [mostrarMenu]="false"></app-cabecalho>
<div class="base-admin">
    <div class="menu-admin">
        <div class="item-menu-admin" (click)="conteudoSelecionado=0">Rentabilidade Acumulada - Fundos</div>
        <div class="item-menu-admin" (click)="conteudoSelecionado=1">Rentabilidade Acumulada - Indices</div>
        <div class="item-menu-admin" (click)="conteudoSelecionado=2">Rentabilidade Mensal</div>
        <div class="item-menu-admin" (click)="conteudoSelecionado=3">Textos Informativos</div>
        <div class="item-menu-admin" (click)="sair()">
            Sair
        </div>
    </div>
    <div class="conteudo-admin">
        <app-rentabilidade-acumulada-fundo-admin *ngIf="conteudoSelecionado===0"></app-rentabilidade-acumulada-fundo-admin>
        <app-rentabilidade-acumulada-indices-admin *ngIf="conteudoSelecionado===1"></app-rentabilidade-acumulada-indices-admin>
        <app-rentabilidade-mensal-admin *ngIf="conteudoSelecionado===2"></app-rentabilidade-mensal-admin>
        <app-outras-informacoes *ngIf="conteudoSelecionado===3"></app-outras-informacoes>
    </div>
</div>