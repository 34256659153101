<div class="a-simetrica" id="simetrica">
    <div class="bg-simetrica">
        <div class="sobre-simetrica">
            <div class="titulo-simetrica">
                Sobre a Simétrica
            </div>
            <div class="texto-simetrica">
                <p>
                    Desde 2002 a Simétrica atua de forma independente, transparente e isenta de conflitos de interesse.
                </p>
                <p>
                    Os principais sócios trabalham juntos desde o início, formando assim uma equipe com grande entrosamento, confiança e agilidade.
                </p>
                <p>
                    Sua orientação e denominação foram influenciadas pelo Prêmio Nobel de Economia de 2001,
                    concedido aos Drs. Joseph Stiglitz, Michael Spence e George A. Akerlof,
                    por seus trabalhos e análises de mercados com informação assimétrica.
                </p>
                <p>
                    A Simétrica é autorizada, pela CVM (Comissão de Valores Mobiliários),
                    a atuar como Administradora de Carteiras de Valores Mobiliários
                    pelo ato declaratório nº 16.471 de 12 de julho de 2018.
                </p>
            </div>
        </div>
        <div class="fundadores">
            <div class="fundador">
                <div class="foto-fundador">
                    <img src="/assets/images/fotos/foto-sandro.png" alt="foto-sandro" />
                </div>
                <div class="bio-fundador">
                    <div class="nome-fundador">
                        Sandro Fernandes
                    </div>
                    <div>
                        Sócio Fundador da Simétrica, com formação em Computação pelo ITA (Instituto Tecnológico da Aeronáutica) em 1991 e Economia pela FEA/USP em 2005.
                        Tem vasta experiência no mercado financeiro, já tendo trabalhado em grandes instituições financeiras como Banco Itaú, Banco Safra e BMG e BNL
                        nas áreas de captação de recursos e concessão de financiamentos.
                    </div>
                </div>
            </div>
            <div class="fundador">
                <div class="foto-fundador">
                    <img src="/assets/images/fotos/foto-ze-renato.png" alt="foto-ze-renato" />
                </div>
                <div class="bio-fundador">
                    <div class="nome-fundador">
                        José Renato Prado Lobo Maiolo
                    </div>
                    <div>
                        Em 2003 passou a fazer parte da equipe da Simétrica, formado em Tecnologia em Processamento de Dados pela Universidade Mackenzie,
                        tem mais de 15 anos de experiência na Área de Controle de Riscos, Compliance e TI. Trabalhou em empresas como Nitro Química Brasileira e Unibanco.
                    </div>
                </div>
            </div>
            <div class="fundador">
                <div class="foto-fundador">
                    <img src="/assets/images/fotos/foto-marco-polo.png" alt="foto-marco-polo" />
                </div>
                <div class="bio-fundador">
                    <div class="nome-fundador">
                        Marco Polo Fernandes, CGA
                    </div>
                    <div>
                        Em 2021 passou a integrar a equipe da Simétrica, formado em Engenharia de Produção pela Universidade Federal de São Carlos – UFSCar, 
                        MBA em Gestão dos Negócios do Varejo pela FIA Fundação Instituto de Administração, trabalhou no Grupo Guararapes por 17 anos nas áreas comercial 
                        internacional e nacional no varejo.
                    </div>
                </div>
            </div>
            <div class="fundador">
                <div class="foto-fundador">
                    <img src="/assets/images/fotos/foto-eduardo.png" alt="foto-eduardo" />
                </div>
                <div class="bio-fundador">
                    <div class="nome-fundador">Eduardo Américo Lins Cancian</div>
                    <div>
                        Sócio Fundador da Simétrica formado em Administração de Empresas pela FEA/USP,
                        MBA Executivo em Controladoria pela USP e MBA Executivo em Finanças pelo INSPER.
                        Trabalhou nos Bancos Bradesco, Itaú BBA e Votorantim como analista sênior (sell side) sendo responsável pela análise
                        fundamentalista dos setores de energia, saneamento e tecnologia. Atuou também junto as empresas de energia
                        Duke Energy e Enron como Gerente Adjunto - Diretoria Financeira.
                    </div>
                </div>
            </div>
        </div>
        <div class="numeros-simetrica">
            <div class="tempo">
                <div class="destaque-tempo">{{dados?.secao1Titulo}}</div>
                <div class="texto-tempo">{{dados?.secao1Texto}}</div>
            </div>
            <div class="resultado meio">
                <div class="numero-resultado">{{dados?.secao2Titulo}}</div>
                <div class="texto-resultado">{{dados?.secao2Texto}}</div>
            </div>
            <div class="resultado fim">
                <div class="numero-resultado">{{dados?.secao3Titulo}}</div>
                <div class="texto-resultado">{{dados?.secao3Texto}}</div>
            </div>
        </div>
    </div>
</div>