import { Component, ViewChild, TemplateRef, Input } from "@angular/core";

export interface ItemMenu {
    descricao: string;
    nomeTag: string;
}

@Component({
    selector: "app-item-menu",
    template: `<ng-template #templateConteudo><ng-content></ng-content></ng-template>`
})
export class ItemMenuComponent {
    @Input() selecionado: boolean;
    @Input() dados: ItemMenu;
    @Input() semClick: boolean;
    @ViewChild("templateConteudo") conteudo: TemplateRef<any>;
}