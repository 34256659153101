import { Component, OnInit } from "@angular/core";
import { Conteudo } from '../../models/conteudo.model';
import { ConteudoService } from '../../services/conteudo.service';

@Component({
    selector: "app-simetrica",
    templateUrl: "./simetrica.component.html",
    styleUrls: ["./simetrica.component.css"]
})
export class SimetricaComponent implements OnInit {
    constructor(private conteudoService: ConteudoService) { }

    dados: Conteudo;

    ngOnInit() {
        this.conteudoService.obter().subscribe(conteudo => this.dados = conteudo);
    }
}