<div class="grid">
    <div class="cabecalho-grid">
        <div class="coluna"
             [class.esquerda]="col.esquerda"
             [class.direita]="col.direita"
             [class.x2]="col.grande"
             [class.centro]="col.centro"
             *ngFor="let col of colunas">{{col.descricao}}</div>
    </div>
    <div class="linha-grid" [class.destaque]="item.destaque" *ngFor="let item of dados">
        <div class="coluna"
             [class.esquerda]="col.esquerda"
             [class.direita]="col.direita"
             [class.x2]="col.grande"
             [class.bold]="col.negrito"
             [class.centro]="col.centro"
             *ngFor="let col of colunas">
            <span *ngIf="col.campo">{{item[col.campo]}}</span>
        </div>
    </div>
</div>
