<div class="cabecalho" id="cabecalho-site">
    <div class="logo">
        <img src="./assets/images/svg/icon_menu_hamburger.svg" class="icone-menu" alt="menu" (click)="abrirMenu()" />
        <img src="./assets/images/png/logo_i.png" alt="logo" class="img" />
        <img src="./assets/images/png/logotipo.png" alt="logotipo" class="tipo" />
    </div>
    <div class="data-menu">
        <div class="data">{{data|date:'dd'}} de {{data|date:'MMMM'}} de {{data|date:'yyyy'}}</div>
        <div class="menu">
            <app-menu-mobile *ngIf="mostrarMenu" (menuSelecionado)="irPara($event)" [exibir]="exibirMenuMobile" (fechar)="fecharMenu()">
                <app-item-menu *ngFor="let menu of menus" [dados]="menu" [selecionado]="menu === menuSelecionado">
                    <span>{{menu.descricao}}</span>
                </app-item-menu>
                <app-item-menu [semClick]="true">
                    <a href="https://simetricagestao.orama.com.br/" target="_blank">Acesse sua conta</a>
                </app-item-menu>
            </app-menu-mobile>
            <app-menu *ngIf="mostrarMenu" #menu (menuSelecionado)="irPara($event)">
                <app-item-menu *ngFor="let menu of menus" [dados]="menu" [selecionado]="menu === menuSelecionado">
                    <span>{{menu.descricao}}</span>
                </app-item-menu>
                <app-item-menu [semClick]="true">
                    <a href="https://simetricagestao.orama.com.br/" target="_blank">Acesse sua conta</a>
                </app-item-menu>
            </app-menu>
            <div class="admin" *ngIf="!mostrarMenu">Administração</div>
        </div>
    </div>
    <div class="linha"></div>
</div>
