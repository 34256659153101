<div class="conteudo-rentabilidade">
    <app-grid *ngIf="dados && dados.length > 0" [dados]="dados">
        <app-grid-column campo="fundo" descricao="Fundo" [grande]="true" [esquerda]="true" [negrito]="true"></app-grid-column>
        <app-grid-column campo="data" descricao="Data"></app-grid-column>
        <app-grid-column campo="cota" descricao="Cota"></app-grid-column>
        <app-grid-column campo="dia" descricao="Dia"></app-grid-column>
        <app-grid-column campo="mes" descricao="Mês"></app-grid-column>
        <app-grid-column campo="ano" descricao="Ano"></app-grid-column>
        <app-grid-column campo="dozeM" descricao="12M"></app-grid-column>
        <app-grid-column campo="inicio" descricao="Início"></app-grid-column>
        <app-grid-column campo="dataInicio" descricao="Data Início"></app-grid-column>
        <app-grid-column campo="pl" descricao="PL (R$)" tipo="currency"></app-grid-column>
        <app-grid-column campo="plMedio12M" descricao="PL Médio 12M (R$)" [grande]="true" [centro]="true"></app-grid-column>
    </app-grid>
</div>