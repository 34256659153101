<div class="conteudo-rentabilidade">
    <app-grid *ngIf="dados && dados.length > 0" [dados]="dados" >
        <app-grid-column campo="indice" descricao="Índices" [grande]="true" [esquerda]="true" [negrito]="true"></app-grid-column>
        <app-grid-column campo="data" descricao="Data" tipo="text"></app-grid-column>
        <app-grid-column></app-grid-column>
        <app-grid-column campo="dia" descricao="Dia"></app-grid-column>
        <app-grid-column campo="mes" descricao="Mês"></app-grid-column>
        <app-grid-column campo="ano" descricao="Ano"></app-grid-column>
        <app-grid-column campo="dozeM" descricao="12M"></app-grid-column>
        <app-grid-column></app-grid-column>
        <app-grid-column></app-grid-column>
        <app-grid-column></app-grid-column>
        <app-grid-column [grande]="true" [centro]="true" ></app-grid-column>
    </app-grid>
</div>