<div class="documentos" id="documentos">
    <div class="titulo-documentos">
        Documentos
    </div>
    <div class="texto-documentos">
        Tenha acesso aos materiais que irão auxiliar no momento de investir.
    </div>
    <div class="links-documentos">
        <a href="/assets/docs/Codigo-de-Etica-e-Conduta.pdf" download>
            <div class="item-link-documentos">
                <div class="icone-link-documentos"><img src="/assets/images/png/icon_download.png" alt="relatorio_mensal" /></div>
                <div class="texto-link-documentos">Código de Ética e Conduta</div>
            </div>
        </a>
        <a href="/assets/docs/Manual-de-Gerenciamento-de-Riscos.pdf" download>
            <div class="item-link-documentos">
                <div class="icone-link-documentos"><img src="/assets/images/png/icon_download.png" alt="relatorio_mensal" /></div>
                <div class="texto-link-documentos">Manual de Gerenciamento de Riscos</div>
            </div>
        </a>
        <a href="/assets/docs/Manual-de-Regras-e-Procedimentos-de-Controles-Internos.pdf" download>
            <div class="item-link-documentos">
                <div class="icone-link-documentos"><img src="/assets/images/png/icon_download.png" alt="relatorio_mensal" /></div>
                <div class="texto-link-documentos">Manual de Regras e Procedimentos de Controles Internos</div>
            </div>
        </a>
        <a href="/assets/docs/Politica-de-Distribuicao-de-Ordens.pdf" download>
            <div class="item-link-documentos">
                <div class="icone-link-documentos"><img src="/assets/images/png/icon_download.png" alt="relatorio_mensal" /></div>
                <div class="texto-link-documentos">Politica de Distribuição de Ordens</div>
            </div>
        </a>
        <a href="/assets/docs/Politica-de-Negociacao-de-Valores.pdf" download>
            <div class="item-link-documentos">
                <div class="icone-link-documentos"><img src="/assets/images/png/icon_download.png" alt="relatorio_mensal" /></div>
                <div class="texto-link-documentos">Politica de Negociação de Valores</div>
            </div>
        </a>
        <a href="/assets/docs/Politica-de-Voto-em-Assembleia.pdf" download>
            <div class="item-link-documentos">
                <div class="icone-link-documentos"><img src="/assets/images/png/icon_download.png" alt="relatorio_mensal" /></div>
                <div class="texto-link-documentos">Politica de Voto em Assembléia</div>
            </div>
        </a>
        <a href="/assets/docs/Politica-de-Prevencao-a-Lavagem-de-Dinheiro-e-Combate-ao-Terrorismo1.pdf" download>
            <div class="item-link-documentos">
                <div class="icone-link-documentos"><img src="/assets/images/png/icon_download.png" alt="relatorio_mensal" /></div>
                <div class="texto-link-documentos">Politica de Prevenção à Lavagem de Dinheiro e Combate ao Terrorismo</div>
            </div>
        </a>
        <a href="/assets/docs/Formulario-de-Referencia-SIMETRICA-01-10-18.pdf" download>
            <div class="item-link-documentos">
                <div class="icone-link-documentos"><img src="/assets/images/png/icon_download.png" alt="relatorio_mensal" /></div>
                <div class="texto-link-documentos">Formulário de Referência da Pessoa Jurídica Anexo 15-II da ICVM 558</div>
            </div>
        </a>
        <a href="/assets/docs/Politica-de-Selecao-de-Prestadores-de-Servico.pdf" download>
            <div class="item-link-documentos">
                <div class="icone-link-documentos"><img src="/assets/images/png/icon_download.png" alt="relatorio_mensal" /></div>
                <div class="texto-link-documentos">Política de Seleção de Prestadores de Serviço</div>
            </div>
        </a>
        <a href="/assets/docs/Politica-de-liquidez.pdf" download>
            <div class="item-link-documentos">
                <div class="icone-link-documentos"><img src="/assets/images/png/icon_download.png" alt="relatorio_mensal" /></div>
                <div class="texto-link-documentos">Política de Gerenciamento de Liquidez</div>
            </div>
        </a>
    </div>
</div>