import { Component, Input, TemplateRef, ViewChild } from "@angular/core";

@Component({
    selector: "app-grid-column",
    template: `<ng-template #templateConteudo><ng-content></ng-content></ng-template>`
})
export class GridColumnComponent {
    @ViewChild("templateConteudo") conteudo: TemplateRef<any>;
    @Input() campo: string;
    @Input() centro: boolean;
    @Input() descricao: string;
    @Input() direita: boolean;
    @Input() esquerda: boolean;
    @Input() grande: boolean;
    @Input() negrito: boolean;
}